/* ==========================================================================
   Whisper skin
   ========================================================================== */

/* Colors */
// $body-color, $background-color, $text-color, $link-color

/* SCSS HEX */
// #fff9ea
//$cultured: #f9f7f5ff;

// https://coolors.co/232b2b-c50017-f5cc00-e7eeec-0059b8
$charleston-green: #232b2bff;
$venetian-red: #c50017;
$jonquil: #f5cc00;
$mint-cream: #e7eeec;
//$denim: #0059b8ff;

$background-color: lighten($mint-cream, 4) !default;
$masthead-background-color: $charleston-green !default;

$text-color: $charleston-green !default;

$masthead-link-color: $mint-cream !default;
$link-color: $venetian-red !default;

$masthead-link-color-hover: $jonquil !default;
$navicon-link-color-hover: mix(#000, $link-color, 0%) !default;
$primary-color: $jonquil !default;
$border-color: $jonquil !default

$form-background-color: darken($background-color, 4) !default;
$footer-background-color: darken($background-color, 8) !default;
$sidebar-background-color: darken($background-color, 8) !default;



// copied from _variables
/* system typefaces */
$serif: noto-serif, Georgia, Times, serif !default;
$serif-c: noto-serif-condensed, Georgia, Times, serif !default;
$sans-serif: noto-sans, -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;
$sans-serif-narrow: noto-sans-condensed, $sans-serif !default;
$monospace: Monaco, Consolas, "Lucida Console", monospace !default;

/* sans serif typefaces */
$sans-serif-narrow: $sans-serif !default;
$helvetica: Helvetica, "Helvetica Neue", Arial, sans-serif !default;

/* serif typefaces */
$georgia: Georgia, serif !default;
$times: Times, serif !default;
$bodoni: "Bodoni MT", serif !default;
$calisto: "Calisto MT", serif !default;
$garamond: Garamond, serif !default;

$global-font-family: $serif-c !default;
$header-font-family: $serif !default;
$caption-font-family: $serif !default;

